.sharethis {
  position: relative;
}

.sharethis > a {
  overflow: hidden;
}

.sharethis > a > span.st_sharethis {
  opacity: 0;
  width: auto;
  height: auto;
  position: absolute;
  inset: 0;
}

.display-inline-block {
  display: inline-block;
}

.arrow {
  position: relative;
}

.arrow:after {
  content: " ";
  pointer-events: none;
  border: solid #0000;
  width: 0;
  height: 0;
  position: absolute;
}

.arrow.arrow-xs:after {
  border-width: 5px;
}

.arrow.arrow-sm:after {
  border-width: 7px;
}

.arrow.arrow-md:after {
  border-width: 12px;
}

.arrow.arrow-lg:after {
  border-width: 20px;
}

.arrow.arrow-xl:after {
  border-width: 30px;
}

.arrow.arrow-top:after, .arrow.arrow-top-left:after, .arrow.arrow-top-right:after {
  bottom: 100%;
}

.arrow.arrow-right:after, .arrow.arrow-right-top:after, .arrow.arrow-right-bottom:after {
  left: 100%;
}

.arrow.arrow-bottom:after, .arrow.arrow-bottom-left:after, .arrow.arrow-bottom-right:after {
  top: 100%;
}

.arrow.arrow-left:after, .arrow.arrow-left-top:after, .arrow.arrow-left-bottom:after {
  right: 100%;
}

.arrow.arrow-top-left:after, .arrow.arrow-bottom-left:after {
  left: 25%;
}

.arrow.arrow-top:after, .arrow.arrow-bottom:after {
  left: 50%;
}

.arrow.arrow-top-right:after, .arrow.arrow-bottom-right:after {
  left: 75%;
}

.arrow.arrow-top.arrow-xsmall:after, .arrow.arrow-top-left.arrow-xsmall:after, .arrow.arrow-top-right.arrow-xsmall:after, .arrow.arrow-bottom.arrow-xsmall:after, .arrow.arrow-bottom-left.arrow-xsmall:after, .arrow.arrow-bottom-right.arrow-xsmall:after {
  margin-left: -5px;
}

.arrow.arrow-top.arrow-small:after, .arrow.arrow-top-left.arrow-small:after, .arrow.arrow-top-right.arrow-small:after, .arrow.arrow-bottom.arrow-small:after, .arrow.arrow-bottom-left.arrow-small:after, .arrow.arrow-bottom-right.arrow-small:after {
  margin-left: -7px;
}

.arrow.arrow-top.arrow-md:after, .arrow.arrow-top-left.arrow-md:after, .arrow.arrow-top-right.arrow-md:after, .arrow.arrow-bottom.arrow-md:after, .arrow.arrow-bottom-left.arrow-md:after, .arrow.arrow-bottom-right.arrow-md:after {
  margin-left: -12px;
}

.arrow.arrow-top.arrow-lg:after, .arrow.arrow-top-left.arrow-lg:after, .arrow.arrow-top-right.arrow-lg:after, .arrow.arrow-bottom.arrow-lg:after, .arrow.arrow-bottom-left.arrow-lg:after, .arrow.arrow-bottom-right.arrow-lg:after {
  margin-left: -20px;
}

.arrow.arrow-top.arrow-xlg:after, .arrow.arrow-top-left.arrow-xl:after, .arrow.arrow-top-left.arrow-xl:after, .arrow.arrow-bottom.arrow-xl:after, .arrow.arrow-bottom-left.arrow-xl:after, .arrow.arrow-bottom-right.arrow-xl:after {
  margin-left: -30px;
}

.arrow.arrow-right-top:after, .arrow.arrow-left-top:after {
  top: 25%;
}

.arrow.arrow-right:after, .arrow.arrow-left:after {
  top: 50%;
}

.arrow.arrow-right-bottom:after, .arrow.arrow-left-bottom:after {
  top: 75%;
}

.arrow.arrow-right.arrow-xsmall:after, .arrow.arrow-right-top.arrow-xsmall:after, .arrow.arrow-right-bottom.arrow-xsmall:after, .arrow.arrow-left.arrow-xsmall:after, .arrow.arrow-left-top.arrow-xsmall:after, .arrow.arrow-left-bottom.arrow-xsmall:after {
  margin-top: -5px;
}

.arrow.arrow-right.arrow-small:after, .arrow.arrow-right-top.arrow-small:after, .arrow.arrow-right-bottom.arrow-small:after, .arrow.arrow-left.arrow-small:after, .arrow.arrow-left-top.arrow-small:after, .arrow.arrow-left-bottom.arrow-small:after {
  margin-top: -7px;
}

.arrow.arrow-right.arrow-md:after, .arrow.arrow-right-top.arrow-md:after, .arrow.arrow-right-bottom.arrow-md:after, .arrow.arrow-left.arrow-md:after, .arrow.arrow-left-top.arrow-md:after, .arrow.arrow-left-bottom.arrow-md:after {
  margin-top: -12px;
}

.arrow.arrow-right.arrow-lg:after, .arrow.arrow-right-top.arrow-lg:after, .arrow.arrow-right-bottom.arrow-lg:after, .arrow.arrow-left.arrow-lg:after, .arrow.arrow-left-top.arrow-lg:after, .arrow.arrow-left-bottom.arrow-lg:after {
  margin-top: -20px;
}

.arrow.arrow-right.arrow-xl:after, .arrow.arrow-right-top.arrow-xl:after, .arrow.arrow-right-top.arrow-xl:after, .arrow.arrow-left.arrow-xl:after, .arrow.arrow-left-top.arrow-xl:after, .arrow.arrow-left-bottom.arrow-xl:after {
  margin-top: -30px;
}

.arrow:after, .arrow.arrow-white:after {
  border-color: #fff0;
}

.arrow.arrow-top:after, .arrow.arrow-top-left:after, .arrow.arrow-top-right:after, .arrow.arrow-white.arrow-top:after, .arrow.arrow-white.arrow-top-left:after, .arrow.arrow-white.arrow-top-right:after {
  border-bottom-color: #fff;
}

.arrow.arrow-bottom:after, .arrow.arrow-bottom-left:after, .arrow.arrow-bottom-right:after, .arrow.arrow-white.arrow-bottom:after, .arrow.arrow-white.arrow-bottom-left:after, .arrow.arrow-white.arrow-bottom-right:after {
  border-top-color: #fff;
}

.arrow.arrow-right:after, .arrow.arrow-right-top:after, .arrow.arrow-right-bottom:after, .arrow.arrow-white.arrow-right:after, .arrow.arrow-white.arrow-right-top:after, .arrow.arrow-white.arrow-right-bottom:after {
  border-left-color: #fff;
}

.arrow.arrow-left:after, .arrow.arrow-left-top:after, .arrow.arrow-left-bottom:after, .arrow.arrow-white.arrow-left:after, .arrow.arrow-white.arrow-left-top:after, .arrow.arrow-white.arrow-left-bottom:after {
  border-right-color: #fff;
}

.arrow.arrow-silver:after {
  border-color: #c3c3c300;
}

.arrow.arrow-silver.arrow-top:after, .arrow.arrow-silver.arrow-top-left:after, .arrow.arrow-silver.arrow-top-right:after {
  border-bottom-color: #c3c3c3;
}

.arrow.arrow-silver.arrow-bottom:after, .arrow.arrow-silver.arrow-bottom-left:after, .arrow.arrow-silver.arrow-bottom-right:after {
  border-top-color: #c3c3c3;
}

.arrow.arrow-silver.arrow-right:after, .arrow.arrow-silver.arrow-right-top:after, .arrow.arrow-silver.arrow-right-bottom:after {
  border-left-color: #c3c3c3;
}

.arrow.arrow-silver.arrow-left:after, .arrow.arrow-silver.arrow-left-top:after, .arrow.arrow-silver.arrow-left-bottom:after {
  border-right-color: #c3c3c3;
}

.arrow.arrow-grey:after {
  border-color: #b5b5b500;
}

.arrow.arrow-grey.arrow-top:after, .arrow.arrow-grey.arrow-top-left:after, .arrow.arrow-grey.arrow-top-right:after {
  border-bottom-color: #b5b5b5;
}

.arrow.arrow-grey.arrow-bottom:after, .arrow.arrow-grey.arrow-bottom-left:after, .arrow.arrow-grey.arrow-bottom-right:after {
  border-top-color: #b5b5b5;
}

.arrow.arrow-grey.arrow-right:after, .arrow.arrow-grey.arrow-right-top:after, .arrow.arrow-grey.arrow-right-bottom:after {
  border-left-color: #b5b5b5;
}

.arrow.arrow-grey.arrow-left:after, .arrow.arrow-grey.arrow-left-top:after, .arrow.arrow-grey.arrow-left-bottom:after {
  border-right-color: #b5b5b5;
}

.ribbon {
  z-index: 1;
  text-align: right;
  z-index: 1000;
  width: 75px;
  height: 75px;
  position: absolute;
  top: -5px;
  right: -5px;
  overflow: hidden;
}

.ribbon span {
  color: #555;
  text-transform: uppercase;
  text-align: center;
  width: 100px;
  text-shadow: none;
  background: #eee;
  font-size: 10px;
  font-weight: bold;
  line-height: 20px;
  display: block;
  position: absolute;
  top: 19px;
  right: -21px;
  transform: rotate(45deg);
  box-shadow: 0 3px 10px -5px #000;
}

.ribbon span:before {
  content: "";
  z-index: -1;
  border: 3px solid #0000;
  border-color: #eee #0000 #0000 #eee;
  position: absolute;
  top: 100%;
  left: 0;
}

.ribbon span:after {
  content: "";
  z-index: -1;
  border: 3px solid #eee;
  border-color: #eee #eee #0000 #0000;
  position: absolute;
  top: 100%;
  right: 0;
}

.ribbon.ribbon-danger span {
  color: #fff;
  background: #d9534f;
}

.ribbon.ribbon-danger span:before {
  border-top-color: #d9534f;
  border-left-color: #d9534f;
}

.ribbon.ribbon-danger span:after {
  border-bottom-color: #d9534f;
  border-right-color: #d9534f;
}

.ribbon.ribbon-warning span {
  color: #fff;
  background: #f0ad4e;
}

.ribbon.ribbon-warning span:before {
  border-top-color: #f0ad4e;
  border-left-color: #f0ad4e;
}

.ribbon.ribbon-warning span:after {
  border-bottom-color: #f0ad4e;
  border-right-color: #f0ad4e;
}

.ribbon.ribbon-info span {
  color: #fff;
  background: #5bc0de;
}

.ribbon.ribbon-info span:before {
  border-top-color: #5bc0de;
  border-left-color: #5bc0de;
}

.ribbon.ribbon-info span:after {
  border-bottom-color: #5bc0de;
  border-right-color: #5bc0de;
}

.ribbon.ribbon-success span {
  color: #fff;
  background: #5cb85c;
}

.ribbon.ribbon-success span:before {
  border-top-color: #5cb85c;
  border-left-color: #5cb85c;
}

.ribbon.ribbon-success span:after {
  border-bottom-color: #5cb85c;
  border-right-color: #5cb85c;
}

.ribbon.ribbon-primary span {
  color: #fff;
  background: #337ab7;
}

.ribbon.ribbon-primary span:before {
  border-top-color: #337ab7;
  border-left-color: #337ab7;
}

.ribbon.ribbon-primary span:after {
  border-bottom-color: #337ab7;
  border-right-color: #337ab7;
}

.ribbon-fixed {
  position: fixed;
  top: 0;
  right: 0;
}

content > .ribbon-fixed {
  top: 25px;
  right: -5px;
}

content > .ribbon-fixed.ribbon-lg {
  top: 24px;
  right: -6px;
}

.ribbon.ribbon-lg {
  z-index: 1000;
  width: 150px;
  height: 150px;
}

.ribbon.ribbon-lg > span {
  width: 200px;
  font-size: 20px;
  line-height: 40px;
  top: 38px;
  right: -42px;
}

.ribbon.ribbon-fixed > span:before, .ribbon.ribbon-fixed > span:after {
  border-width: 3px;
}

body > .ribbon.ribbon-tl {
  inset: 0 auto auto 0;
  transform: rotate(-90deg);
}

body > .ribbon.ribbon-tl > span:before, body > .ribbon.ribbon-tl > span:after {
  display: none;
}

body > .ribbon.ribbon-bl {
  inset: auto auto 0 0;
  transform: rotate(180deg);
}

body > .ribbon.ribbon-bl > span {
  transform: rotate(225deg);
}

body > .ribbon.ribbon-bl > span:before, body > .ribbon.ribbon-bl > span:after {
  display: none;
}

.border, .border-thin, .border-medium, .border-thick {
  border: 0 solid #fff;
}

.border-dashed {
  border-style: dashed;
}

.border-dotted {
  border-style: dotted;
}

.border-inset {
  border-style: inset;
}

.border-outset {
  border-style: outset;
}

.border-groove {
  border-style: groove;
}

.border-ridge {
  border-style: ridge;
}

.border-double {
  border-style: double;
}

.border-grey {
  border-color: #b5b5b5;
}

.border-light-grey {
  border-color: #e5e5e5;
}

.border-blue {
  border-color: #1c3f95;
}

.border-yellow {
  border-color: #ffd204;
}

.border-white {
  border-color: #fff;
}

.border-all {
  border-width: 1px;
}

.border-top, .border-all {
  border-top-width: 1px;
}

.border-top.border-medium, .border-all.border-medium {
  border-top-width: 3px;
}

.border-top.border-thick, .border-all.border-thick {
  border-top-width: 5px;
}

.border-right, .border-all {
  border-right-width: 1px;
}

.border-right.border-medium, .border-all.border-medium {
  border-right-width: 3px;
}

.border-right.border-thick, .border-all.border-thick {
  border-right-width: 5px;
}

.border-bottom, .border-all {
  border-bottom-width: 1px;
}

.border-bottom.border-medium, .border-all.border-medium {
  border-bottom-width: 3px;
}

.border-bottom.border-thick, .border-all.border-thick {
  border-bottom-width: 5px;
}

.border-left, .border-all {
  border-left-width: 1px;
}

.border-left.border-medium, .border-all.border-medium {
  border-left-width: 3px;
}

.border-left.border-thick, .border-all.border-thick {
  border-left-width: 5px;
}

.border > span.border {
  display: inline-block;
}

.border > span.border, .border-thin > span.border, .border-thin > span.border-thin {
  margin-bottom: -1px;
}

.border-medium > span.border, .border-medium > span.border-thin, .border-thick > span.border-medium {
  margin-bottom: -2px;
}

.border > span.border-medium, .border-thin > span.border-medium {
  margin-bottom: -2px;
  padding-bottom: 2px;
}

.border-medium > span.border-thick {
  margin-bottom: -4px;
  padding-bottom: 4px;
}

.border-padding.border-bottom {
  margin-bottom: 20px;
  padding-bottom: 20px;
}

li.border-padding.border-bottom {
  padding-bottom: 10px;
}

li.border-margin.border-bottom {
  margin-bottom: 10px;
}

.no-border, .list-group.no-border > li.list-group-item, .list-group.no-border > li.list-group-item > a.list-group-item {
  border-style: none;
  border-width: 0;
}

.panel.no-border {
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media screen and (width <= 768px) {
  .no-border-xs {
    border-style: none;
    border-width: 0;
  }
}

@media (width >= 768px) and (width <= 991px) {
  .no-border-sm {
    border-style: none;
    border-width: 0;
  }
}

@media (width >= 992px) and (width <= 1199px) {
  .no-border-md {
    border-style: none;
    border-width: 0;
  }
}

@media (width >= 1200px) {
  .no-border-lg {
    border-style: none;
    border-width: 0;
  }
}

.shadow-xs {
  min-height: 6px;
}

.shadow-sm {
  min-height: 12px;
}

.shadow-md {
  min-height: 18px;
}

.shadow-lg {
  min-height: 24px;
}

.shadow-xl {
  min-height: 30px;
}

.shadow, .shadow.shadow-white {
  background: -o-linear-gradient(top, #fffc 0%, #0000 100%);
  background: -ms-linear-gradient(top, #fffc 0%, #0000 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#ffffff", endColorstr= "#00000000", GradientType= 0);
  background: -moz-linear-gradient(top, #fffc 0%, #0000 100%);
  background: -o-linear-gradient(top, #fffc 0%, #0000 100%);
  background: linear-gradient(#fffc 0%, #0000 100%);
}

.shadow.shadow-silver {
  background: -o-linear-gradient(top, #d7d7d7cc 0%, #0000 100%);
  background: -ms-linear-gradient(top, #d7d7d7cc 0%, #0000 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#d7d7d7", endColorstr= "#00000000", GradientType= 0);
  background: -moz-linear-gradient(top, #d7d7d7cc 0%, #0000 100%);
  background: -o-linear-gradient(top, #d7d7d7cc 0%, #0000 100%);
  background: linear-gradient(#d7d7d7cc 0%, #0000 100%);
}

.shadow.shadow-grey {
  background: -o-linear-gradient(top, #81818199 0%, #0000 100%);
  background: -ms-linear-gradient(top, #81818199 0%, #0000 100%);
  filter: progid:DXImageTransform. Microsoft. gradient(startColorstr= "#818181", endColorstr= "#00000000", GradientType= 0);
  background: -moz-linear-gradient(top, #81818199 0%, #0000 100%);
  background: -o-linear-gradient(top, #81818199 0%, #0000 100%);
  background: linear-gradient(#81818199 0%, #0000 100%);
}

.shadow-absolute {
  position: absolute;
}

.shadow-absolute.shadow-top {
  top: 0;
  left: 0;
  right: 0;
}

.glyphicon:empty {
  width: auto;
}

.glyphicons:empty:before {
  margin-right: 0;
}

.glyphicons:before {
  position: relative;
  top: -2px;
}

.glyphicon:not(:empty) {
  padding-left: 12px;
}

.glyphicon > .glyphicon {
  position: absolute;
  top: 0;
  left: 0;
}

.fa-pdf:before, .fa-doc:before, .fa-htm:before, .fa-rtf:before, .fa-txt:before, .fa-wpd:before, .fa-wps:before {
  content: "";
}

.fa-jpg:before, .fa-png:before, .fa-gif:before {
  content: "";
}

.row-flex {
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.row-flex.flex-middle {
  align-items: center;
}

.row-flex > * {
  padding: 0 15px;
}

.row-flex > .col-wide {
  flex: 1;
}

.row-flex > .col-20 {
  width: 20%;
}

.tooltip {
  z-index: 50000;
}

.tooltip-inner {
  max-width: 100%;
}

.legend {
  width: 100%;
  font-size: 21px;
  font-weight: normal;
  line-height: inherit;
  color: #333;
  text-align: left;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
  padding: 0;
}

.col-lg-half, .col-md-half, .col-sm-half, .col-xs-half {
  width: 4.16667%;
}

.row.tight {
  padding-left: 10px;
  padding-right: 10px;
}

.row.tight > div[class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.btn .caret {
  margin-top: -2px;
  margin-left: 3px;
}

.btn.dropdown-toggle .caret {
  margin-left: 0;
}

.btn-group > .btn.btn-sm + .dropdown-toggle, .btn-group > .btn.btn-xs + .dropdown-toggle {
  padding-left: 5px;
  padding-right: 5px;
}

.btn-group > .btn-sm .caret, .btn-group > .btn-xs .caret {
  border-top-width: 3px;
  border-left-width: 3px;
  border-right-width: 3px;
}

.btn-group.btn-block {
  display: table;
}

.btn-group.btn-block > .btn {
  float: none;
  display: table-cell;
}

[data-toggle="buttons"].btn-form-controls > .btn > input[type="radio"], [data-toggle="buttons"].btn-form-controls > .btn > input[type="checkbox"] {
  display: inline-block;
}

label.btn > input[type="radio"], label.btn > input[type="checkbox"] {
  display: none;
}

label.btn.disabled.active, label.btn.active[disabled] {
  background-color: #ebebeb;
  border-color: #adadad;
}

.btn-padded {
  padding: 15px;
}

.btn-padded :last-child {
  margin-bottom: 0;
}

.btn-wrap, .btn .help-block {
  white-space: normal;
}

.btn dl {
  margin: 0;
}

.btn-link {
  border-radius: 0;
}

.btn.no-padding {
  padding: 0;
}

.btn-group > .btn-block:not(.btn-group) {
  padding-left: 12px;
  padding-right: 12px;
}

.btn-group > .btn-block.text-left > .caret {
  float: right;
}

.multiselect.text-left {
  text-align: left;
}

.multiselect.text-left b.caret {
  position: absolute;
  top: 16px;
  right: 8px;
}

.btn.btn-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.btn.btn-icon:not(.active) > i.not-active, .btn.btn-icon:not(.active) i.icon-not-active {
  display: inline-block;
}

.btn.btn-icon:not(.active) > i.active, .btn.btn-icon:not(.active) i.icon-active, .btn.btn-icon.active > i.not-active, .btn.btn-icon.active i.icon-not-active {
  display: none;
}

.btn.btn-icon.active > i.active, .btn.btn-icon.active i.icon-active {
  display: inline-block;
}

.input-group > .input-group-btn > .btn > .fa {
  line-height: inherit;
}

.input-group > input.form-control + .input-group-btn, .input-group > input.form-control + .input-group-btn > .btn:first-child:last-child {
  border-left: 0;
}

.input-group > .input-group-btn:not(:first-child) {
  border-left: 1px solid #ccc;
}

.input-xs {
  border-radius: 3px;
  height: 22px;
  padding: 5px;
  font-size: 12px;
  line-height: 1.5;
}

.input-group-xs > .form-control, .input-group-xs > .input-group-addon, .input-group-xs > .input-group-btn > .btn {
  height: 22px;
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
}

.list-group-striped > li:nth-child(odd):not(.active), .list-group-striped > li:nth-child(odd):not(.active) > a.list-group-item {
  background-color: #f9f9f9;
}

.panel > .list-group-striped > li:nth-child(odd):not(.active), .panel > .list-group-striped > li:nth-child(odd):not(.active) > a.list-group-item, .panel > .panel-body + .list-group-striped > li:nth-child(2n):not(.active), .panel > .list-group-striped > li:nth-child(2n):not(.active) > a.list-group-item {
  background-color: #0000;
}

.panel > .list-group-striped > li:nth-child(2n):not(.active), .panel > .list-group-striped > li:nth-child(2n):not(.active) > a.list-group-item, .panel > .panel-body + .list-group-striped > li:nth-child(odd):not(.active), .panel > .panel-body + .list-group-striped > li:nth-child(odd):not(.active) > a.list-group-item {
  background-color: #f9f9f9;
}

.list-group-striped > li:nth-child(odd):not(.active) > a.list-group-item:hover, .list-group-striped > li:nth-child(2n):not(.active) > a.list-group-item:hover {
  background-color: #f5f5f5;
}

.list-group-item.active, .list-group-item.active > a.list-group-item, .dropdown-menu > .active a {
  background-color: #204498;
  border-color: #204498;
}

.list-group-item.active.active-yellow, .list-group-item.active.active-yellow > a.list-group-item, .dropdown-menu > .active.active-yellow a {
  background-color: #ffce00;
}

.list-group-item.active, .dropdown-menu > .active, .list-group-item.active a, .dropdown-menu > .active > a {
  color: #fff;
}

.list-group > li.list-group-item > a.list-group-item {
  border-top-style: none;
  border-top-width: 0;
  border-bottom-style: none;
  border-bottom-width: 0;
  border-radius: 0;
  margin: -10px -16px;
}

.panel .list-group > li.list-group-item > a.list-group-item {
  margin: -10px -15px;
}

.list-group > li.list-group-item span.list-group-addon {
  color: #555;
  text-align: center;
  background-color: #eee;
  border-right: 1px solid #ccc;
  margin: -10px 15px -10px -15px;
  padding: 13px 15px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.list-group > li.list-group-item:first-child > a.list-group-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.list-group > li.list-group-item:first-child > a.list-group-item:first-child > span.list-group-addon:first-child {
  border-top-left-radius: 4px;
}

.list-group > li.list-group-item:first-child > a.list-group-item:first-child > span.list-group-addon:last-child:not(:first-child) {
  border-top-right-radius: 4px;
}

.list-group > li.list-group-item:last-child > a.list-group-item:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.list-group > li.list-group-item:last-child > a.list-group-item:last-child > span.list-group-addon:first-child {
  border-bottom-left-radius: 4px;
}

.list-group > li.list-group-item:last-child > a.list-group-item:last-child > span.list-group-addon:last-child:not(:first-child) {
  border-bottom-right-radius: 4px;
}

.list-group > .list-group-item > a.list-group-item-link {
  display: block;
}

.list-group > .list-group-item.no-padding {
  padding: 0;
}

.list-group > .list-group-item.no-padding a.list-group-item-link {
  padding: 10px 15px;
}

.list-group > .list-group-item a.list-group-item-link {
  text-decoration: none;
}

.list-group-item dl {
  margin-top: 0;
}

.list-group-item-text * {
  margin-bottom: 10px;
}

.list-group-item-text :last-child {
  margin-bottom: 0;
}

.list-group-plain > .list-group-item, .list-group-plain > .list-group-item > a.list-group-item {
  border-style: none;
  border-width: 0;
}

.list-group-plain > .list-group-item.active, .list-group-plain > .list-group-item.active > a, .list-group-plain > .list-group-item > a.list-group-item.active {
  background-color: #f5f5f5;
}

.list-group-plain > .list-group-item, .list-group-plain > .list-group-item > a, .list-group-plain > .list-group-item > a.list-group-item, .list-group-plain > .list-group-item.active, .list-group-plain > .list-group-item.active > a, .list-group-plain > .list-group-item > a.list-group-item.active {
  color: #000;
}

.list-group-compact > li.list-group-item, .list-group-compact > li.list-group-item > a.list-group-item {
  padding: 5px 10px;
}

.list-group-compact > li.list-group-item > a.list-group-item {
  margin: -5px -10px;
}

.list-group-compact > li.list-group-item > a.list-group-item > span.label.pull-right {
  margin-top: 7px;
}

.dl-inline {
  margin: 0 0 10px;
}

.dl-inline.text-center {
  margin: 0 auto 10px;
  display: inline-block;
}

.dl-inline dt, .dl-inline dd {
  text-align: left;
  width: auto;
  display: inline;
}

.dl-inline dt {
  margin: 0 5px 0 0;
}

.dl-inline dd:not(:last-child) {
  margin: 0 15px 0 0;
}

.dl-striped dt, .dl-striped dd {
  padding: 5px;
}

.dl-striped dt, .dl-striped.dl-striped-pairs dt:nth-of-type(odd), .dl-striped.dl-striped-pairs dd:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.dl-striped.dl-striped-pairs dt:nth-of-type(2n), .dl-striped.dl-striped-pairs dd:nth-of-type(2n) {
  background-color: #0000;
}

.dl-colonized > dt:after {
  content: ":";
}

.dl-horizontal > dt.text-left {
  text-align: left;
}

.dl-horizontal > dt:first-child, .dl-horizontal.row > dt {
  clear: none;
}

.dl-horizontal.row > dt.col-lg-1, .dl-horizontal.row > dt.col-md-1, .dl-horizontal.row > dt.col-sm-1, .dl-horizontal.row > dt.col-xs-1 {
  width: 8.33333%;
}

.dl-horizontal.row > dt.col-lg-2, .dl-horizontal.row > dt.col-md-2, .dl-horizontal.row > dt.col-sm-2, .dl-horizontal.row > dt.col-xs-2 {
  width: 16.6667%;
}

.dl-horizontal.row > dt.col-lg-3, .dl-horizontal.row > dt.col-md-3, .dl-horizontal.row > dt.col-sm-3, .dl-horizontal.row > dt.col-xs-3 {
  width: 25%;
}

.dl-horizontal.row > dt.col-lg-4, .dl-horizontal.row > dt.col-md-4, .dl-horizontal.row > dt.col-sm-4, .dl-horizontal.row > dt.col-xs-4 {
  width: 33.3333%;
}

.dl-horizontal.row > dt.col-lg-5, .dl-horizontal.row > dt.col-md-5, .dl-horizontal.row > dt.col-sm-5, .dl-horizontal.row > dt.col-xs-5 {
  width: 41.6667%;
}

.dl-horizontal.row > dt.col-lg-6, .dl-horizontal.row > dt.col-md-6, .dl-horizontal.row > dt.col-sm-6, .dl-horizontal.row > dt.col-xs-6 {
  width: 50%;
}

.dl-horizontal.row > dt.col-lg-7, .dl-horizontal.row > dt.col-md-7, .dl-horizontal.row > dt.col-sm-7, .dl-horizontal.row > dt.col-xs-7 {
  width: 58.3333%;
}

.dl-horizontal.row > dt.col-lg-8, .dl-horizontal.row > dt.col-md-8, .dl-horizontal.row > dt.col-sm-8, .dl-horizontal.row > dt.col-xs-8 {
  width: 66.6667%;
}

.dl-horizontal.row > dt.col-lg-9, .dl-horizontal.row > dt.col-md-9, .dl-horizontal.row > dt.col-sm-9, .dl-horizontal.row > dt.col-xs-9 {
  width: 75%;
}

.dl-horizontal.row > dt.col-lg-10, .dl-horizontal.row > dt.col-md-10, .dl-horizontal.row > dt.col-sm-10, .dl-horizontal.row > dt.col-xs-10 {
  width: 83.3333%;
}

.dl-horizontal.row > dt.col-lg-11, .dl-horizontal.row > dt.col-md-11, .dl-horizontal.row > dt.col-sm-11, .dl-horizontal.row > dt.col-xs-11 {
  width: 91.6667%;
}

.dl-horizontal.row > dt.col-lg-12, .dl-horizontal.row > dt.col-md-12, .dl-horizontal.row > dt.col-sm-12, .dl-horizontal.row > dt.col-xs-12 {
  width: 100%;
}

.dl-horizontal.row > dd {
  margin-left: 0;
}

.dl-horizontal > dt.dt-block, .dl-horizontal.dt-narrow > dt.dt-block {
  float: none;
  text-align: left;
  width: auto;
}

.dl-horizontal > dd.dd-block {
  clear: left;
}

.dl-horizontal.dt-narrow > dt {
  float: left;
  width: 80px;
}

.dl-horizontal.dt-narrow > dd {
  margin-left: 100px;
}

.dl-horizontal.dt-narrow.dd-float > dt {
  width: auto;
}

.dl-horizontal.dt-narrow.dd-float > dd {
  float: left;
  margin-left: 5px;
}

.dl-horizontal.dt-icons > dt {
  float: left;
  width: 14px;
}

.dl-horizontal.dt-icons > dd {
  margin-left: 24px;
}

.dl-horizontal.dt-wide > dt {
  width: 240px;
}

.dl-horizontal.dt-wide > dd {
  margin-left: 260px;
}

@media (width >= 768px) {
  .navbar-collapse {
    max-height: 100%;
  }
}

.nav.navbar-nav.nav-justified, .nav.navbar-nav.nav-justified > li {
  float: none;
}

.nav.nav-pills.nav-stacked.nav-tight > li > a {
  padding: 5px;
}

div.dropdown-menu {
  padding: 8px;
}

div.dropdown-menu > .dropdown-menu.dropdown-body {
  float: none;
  max-height: 250px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-style: none;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  position: static;
  overflow: auto;
}

.dropdown-menu.open, .open > div.dropdown-menu > .dropdown-menu.dropdown-body {
  display: block;
}

.dropdown-menu li > strong {
  padding: 3px 20px;
}

.dropdown-menu li > a.padding-left-2x {
  padding-left: 40px;
}

.dropdown-menu li > a.padding-left-3x {
  padding-left: 60px;
}

.dropdown-menu li > a.padding-left-4x {
  padding-left: 80px;
}

.dropdown-menu li > a.padding-left-5x {
  padding-left: 100px;
}

.label-new, .badge-new {
  color: #333 !important;
  background-color: #ff0 !important;
}

.label-as-badge {
  border-radius: 1em;
  line-height: 1.1;
}

.tabs-bordered > .nav-tabs {
  border-bottom: 0;
  padding: 0 15px;
}

.tabs-bordered > .nav-tabs.nav-justified {
  margin-bottom: -1px;
}

.tabs-bordered > .nav-tabs.no-padding {
  padding: 0;
}

.tabs-bordered > .nav-tabs > li > a {
  border-bottom: 0 !important;
}

.tabs-bordered > .tab-content > .tab-pane {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
}

.tabs-bordered > .nav-tabs.no-padding + .tab-content > .tab-pane:first-child, .tabs-bordered > .nav-tabs.nav-justified.no-padding + .tab-content > .tab-pane:first-child:not(:last-child) {
  border-radius: 0 4px 4px;
}

.tabs-bordered > .nav-tabs.nav-justified.no-padding + .tab-content > .tab-pane:last-child:not(:first-child) {
  border-radius: 4px 0 4px 4px;
}

.tabs-bordered > .tab-content > .tab-pane > .form-group:last-child {
  margin-bottom: 0;
}

.nav > li > .btn-group {
  padding: 0;
  display: block;
  position: relative;
}

.nav-tabs > li > .btn-group {
  border: 1px solid #0000;
  border-radius: 4px 4px 0 0;
  margin-right: 2px;
  line-height: 1.42857;
}

.nav-tabs > li.active > .btn-group {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: #0000;
}

.nav-tabs > li.active > .btn-group > .btn {
  cursor: default;
  border: 0;
  padding: 10px 15px;
}

.nav-tabs > li > .btn-group > .btn:focus, .nav-tabs > li > .btn-group > .btn:hover, .nav-tabs > li > .btn-group > .btn:active, .nav-tabs > li > .btn-group.open > .btn {
  background-color: #fff;
}

.nav-tabs > li.active > .btn-group > .btn.dropdown-toggle {
  color: #333;
  cursor: pointer;
  border-left: 1px solid #ccc;
  margin: 10px 0;
  padding: 0 10px;
}

.nav-tabs > li > .btn-group.open > .btn.dropdown-toggle {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.nav-tabs > li.active > .btn-group > .btn.dropdown-toggle .caret {
  border-top-color: #333;
  border-bottom-color: #333;
}

.nav-tabs > li.active > .btn-group > .dropdown-menu {
  margin-top: 0;
}

.nav-tabs > li.active > .btn-group > .dropdown-menu.pull-right {
  margin-right: -1px;
}

.position-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.form-tight .form-group, .form-group.tight {
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.form-tight .form-group > div[class*="col-"], .form-group.tight > div[class*="col-"] {
  padding-left: 5px;
  padding-right: 5px;
}

.form-tight .form-group > div.alert {
  margin-left: 5px;
  margin-right: 5px;
}

.form-tight .form-group > div.alert:first-child {
  margin-top: 5px;
  margin-bottom: 5px;
}

.form-tight .form-group .margin-top, .form-group.tight .margin-top {
  margin-top: 10px;
}

.form-tight .form-group .margin-bottom, .form-group.tight .margin-bottom {
  margin-bottom: 10px;
}

.form-group.required label {
  font-weight: bold;
}

.form-group.optional label, .form-group.required label.btn {
  font-weight: normal;
}

.input-xl {
  font-size: 24px;
}

.input-group .input-group-addon.btn-group {
  border: 0;
  padding: 0;
}

.input-group .input-group-addon.btn-group .btn {
  float: none;
  border-radius: 0;
}

.input-group .input-group-addon.btn-group .btn + .btn {
  margin-left: -4px;
}

.input-group .input-group-addon:first-child .btn-group .btn:first-child {
  border-right: 0;
  border-radius: 4px 0 0 4px;
}

.input-group .input-group-btn:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group .input-group-btn:not(:first-child):not(:last-child) > .btn {
  border-right: 0;
  border-radius: 0;
}

.input-group .input-group-addon:last-child.btn-group .btn:first-child {
  border-left: 0;
}

.input-group .input-group-addon:last-child.btn-group .btn:last-child {
  border-radius: 0 4px 4px 0;
}

.input-group .input-group-addon.captcha {
  padding: 0;
}

.input-group .input-group-addon.captcha img {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  height: 73px;
}

.input-group input#captcha {
  height: 75px;
}

.input-group-btn[disabled] {
  cursor: not-allowed;
}

.input-group-addon:not(:last-child) {
  border-right: 0;
}

.input-group-addon + .input-group-addon {
  border-left: 1px solid #ccc !important;
}

.input-group {
  width: 100%;
}

.input-group > .form-control:only-child {
  border-radius: 4px;
}

.input-group-btn:first-child > .btn.btn-sm, .input-group-btn:first-child > .btn-group.btn-sm {
  margin-right: -2px;
}

.form-control.auto-height {
  height: auto;
}

.form-control.auto-height + .input-group-btn {
  min-width: 37px;
}

.form-control.auto-height + .input-group-btn > .btn {
  position: absolute;
  top: 0;
  bottom: 0;
}

.form-control.auto-height > label {
  margin: 0;
}

.form-control.auto-height > .radio {
  padding: 3px 0;
}

textarea.form-control {
  resize: vertical;
  min-height: 100px;
  max-height: 600px !important;
}

textarea.form-control.auto-height {
  vertical-align: top;
  transition: height .2s;
}

textarea.form-control.auto-height.short {
  min-height: 2em;
}

div.form-control-wrap {
  padding-right: 6px;
}

div.form-control > textarea.form-control, div.form-control > select[multiple].form-control {
  padding: 0 14px 0 0;
  transition: none;
}

div.form-control > textarea.form-control, div.form-control > select[multiple].form-control, .form-control.no-border {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
}

.form-control.focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px #00000013, 0 0 8px #66afe999;
}

.form-control .form-group:first-child {
  margin-top: 9px;
}

.form-control .form-group:last-child {
  margin-bottom: 9px;
}

.form-control-static > strong:first-child {
  margin-right: 10px;
}

.panel > .collapse > .form-group, .panel > .collapsing > .form-group, .panel > .in > .form-group {
  margin-bottom: 0;
}

.form-group .error .form-control:not(:last-child), .form-group .error .input-group > .input-group-btn:first-child > .btn, .form-group .error .input-group > .input-group-addon.captcha:first-child > img, .form-group .error .input-group *, .form-group .error .btn-group > .btn, .form-group .error .btn-group-vertical.btn-block > .btn:last-child, .form-group.error .form-control:not(:last-child), .form-group.error .input-group > .input-group-btn:first-child > .btn, .form-group.error .input-group > .input-group-addon.captcha:first-child > img, .form-group.error .input-group *, .form-group.error .btn-group > .btn, .form-group.error .btn-group-vertical.btn-block > .btn:last-child, td.error .form-control:not(:last-child), td.error .input-group > .input-group-btn:first-child > .btn, td.error .input-group > .input-group-addon.captcha:first-child > img, td.error .input-group *, td.error .btn-group > .btn, td.error .btn-group-vertical.btn-block > .btn:last-child {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.form-group .error .form-control + .alert, .form-group.error .form-control + .alert, td.error .form-control + .alert, .form-group .error .input-group + .alert, .form-group.error .input-group + .alert, td.error .input-group + .alert, .form-group .error .btn-group.btn-block + .alert, .form-group .error .btn-group-vertical.btn-block + .alert, .form-group.error .btn-group.btn-block + .alert, .form-group.error .btn-group-vertical.btn-block + .alert, td.error .btn-group.btn-block + .alert, td.error .btn-group-vertical.btn-block + .alert {
  border-top-width: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-bottom: 0;
}

.form-group .error .checkbox, .form-group.error .checkbox {
  margin-bottom: 5px;
}

.form-group .error .checkbox + .alert:not(:first-child), .form-group.error .checkbox + .alert:not(:first-child) {
  border-top-width: 1px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.input-group > .input-group-addon > label {
  cursor: pointer;
  margin: -6px -12px;
  padding: 6px 12px;
}

.input-group > .input-group-addon > .glyphicons:before, .input-group > .input-group-addon > label > .glyphicons:before {
  margin: 0;
}

.input-group > .input-group-addon > label > input[type="checkbox"], .input-group > .input-group-addon > label > input[type="radio"] {
  cursor: pointer;
  width: 18px;
  height: 18px;
  margin: 0;
}

select > option.placeholder, select.empty, .form-control.empty {
  color: #b299a5;
}

form .form-group .help-block {
  margin-bottom: 0;
}

.form-group + .help-block {
  margin-top: -10px;
  margin-bottom: 15px;
}

.form-control.loading {
  background-position: right 15px center;
  background-repeat: no-repeat;
  background-size: auto 50%;
}

.twitter-typeahead {
  display: block !important;
}

.tt-dropdown-menu {
  background-color: #fff;
  border: 1px solid #0003;
  border-radius: 4px;
  width: 100%;
  max-height: 250px;
  margin-top: 12px;
  padding: 8px 0;
  overflow-y: auto;
  box-shadow: 0 5px 10px #0003;
}

.tt-query {
  box-shadow: inset 0 1px 1px #00000013;
}

.tt-hint {
  color: #999;
}

.tt-suggestion {
  cursor: pointer;
  border-top: 1px solid #999;
  padding: 3px 20px;
}

.tt-suggestion:first-child {
  border: 0;
}

.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: #0097cf;
}

.tt-suggestion p {
  margin: 0;
}

.gist {
  font-size: 14px;
}

.datepicker table tr td.disabled, .datepicker table tr td.disabled:hover {
  cursor: not-allowed;
}

::-webkit-input-placeholder {
  color: #b299a5;
}

:-moz-placeholder {
  color: #b299a5;
  opacity: 1;
}

::-moz-placeholder {
  color: #b299a5;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #b299a5;
}

.progress-thin {
  height: 4px;
}

.progress-labels {
  margin-top: 35px;
  margin-bottom: 18px;
}

.progress-label {
  color: #666;
  position: relative;
  top: -20px;
}

.progress-bubbles .progress-label {
  top: -40px;
}

.progress.progress-overflow {
  overflow: visible;
}

.progress.progress-overflow > .progress-bar:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.progress.progress-overflow > .progress-bar:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.progress > .progress-bar {
  position: relative;
}

.progress > .progress-bar > .progress-bubble {
  background-color: #428bca;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  padding: 9px;
  font-size: 150%;
  display: inline-block;
  position: absolute;
  top: -18px;
  left: 50%;
}

.progress > .progress-bar.progress-bar-success > .progress-bubble {
  background-color: #5cb85c;
}

.progress > .progress-bar.progress-bar-disabled, .progress > .progress-bar.progress-bar-disabled > .progress-bubble {
  background-color: #e6e6e6;
}

.progress > .progress-bar > .progress-bubble > i.fa, .progress > .progress-bar > .progress-bubble > i.fal, .progress > .progress-bar > .progress-bubble > i.fas, .progress > .progress-bar > .progress-bubble > i.far, .progress > .progress-bar > .progress-bubble > i.fab {
  margin-top: 2px;
}

.progress-bar-empty {
  background-color: #0000;
  background-image: none;
}

.progress.no-margin {
  margin-bottom: 0;
}

.progress.active .progress-bar-empty, [disabled] .progress.active * {
  -ms-animation: none;
  animation: none;
}

.progress > span {
  margin-top: 5px;
  display: block;
}

[disabled] .progress.progress-striped .progress-bar {
  background-color: #0000;
  background-image: none;
}

[disabled] .progress.progress-striped .progress-bar span.text {
  color: #333;
}

.progress-xs {
  height: 5px;
}

.progress-sm {
  height: 10px;
}

.progress-bar span.text {
  position: absolute;
  top: 2px;
}

.progress-bar span.text-left {
  left: 4px;
}

.progress-bar span.text-center {
  left: 4px;
  right: 4px;
}

.progress-bar span.text-right {
  right: 4px;
}

.iframe-responsive {
  height: 0;
  padding-top: 30px;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.iframe-responsive.youtube-no-controls {
  padding-top: 0;
}

.iframe-responsive iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.modal {
  padding: 60px;
}

@media (width <= 767px) {
  .modal {
    padding: 15px;
  }
}

.modal .modal-dialog {
  margin: 0 auto;
}

.modal .modal-dialog .modal-content .modal-body.loading {
  background-position: 50%;
  background-repeat: no-repeat;
}

.modal > .modal-dialog > .modal-content > .modal-header > .close {
  width: 48px;
  height: 48px;
  margin-top: -12px;
  font-size: 48px;
}

.modal .modal-dialog .modal-content .modal-body + .modal-body {
  border-top: 1px solid #e5e5e5;
}

.modal.bootbox-alert > .modal-dialog > .modal-content > .modal-footer {
  margin-top: 0;
}

.popover {
  padding: 0;
}

.popover.popover-inline {
  z-index: 0;
}

.popover.bottom .arrow:after {
  border-bottom-color: #f7f7f7;
}

.panel.no-border {
  border-style: none;
  border-width: 0;
}

.no-border-radius, .list-group.no-border-radius > li.list-group-item, .list-group.no-border-radius > li.list-group-item > a.list-group-item {
  border-radius: 0;
}

.no-box-shadow {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.panel.no-bg-color {
  background-color: #0000;
}

.panel-group .panel {
  overflow: visible;
}

.panel-group .panel .panel-heading .panel-title .panel-toggle {
  text-decoration: none;
  display: block;
}

.panel > .panel-heading > .panel-title.small {
  font-size: 14px;
}

.panel > :last-child, .panel > .panel-body > :last-child {
  margin-bottom: 0;
}

.panel .panel-body + .panel-body {
  border-top: 1px solid #ddd;
}

.panel > .panel-filter.input-group > .input-group-addon, .panel > .panel-filter.input-group > .form-control {
  border-radius: 0;
}

.panel > .panel-heading > .panel-title > :not(.btn-group) > .btn {
  margin-top: -10px;
  margin-bottom: -10px;
}

.panel > .panel-heading > .panel-title > .pull-right:not(.btn-group) > .btn {
  margin-right: -10px;
}

.panel-100 > .panel-body, .panel-200 > .panel-body, .panel-300 > .panel-body, .panel-400 > .panel-body, .panel-500 > .panel-body, .panel-600 > .panel-body {
  margin: 15px;
  padding: 0 15px 0 0;
  overflow: auto;
}

.panel-100 > .panel-body.no-scrolling, .panel-200 > .panel-body.no-scrolling, .panel-300 > .panel-body.no-scrolling, .panel-400 > .panel-body.no-scrolling, .panel-500 > .panel-body.no-scrolling, .panel-600 > .panel-body.no-scrolling {
  margin: 0;
  padding: 15px;
  overflow: hidden;
}

.panel-100 > .panel-body {
  height: 100px;
}

.panel-200 > .panel-body {
  height: 200px;
}

.panel-300 > .panel-body {
  height: 300px;
}

.panel-400 > .panel-body {
  height: 400px;
}

.panel-500 > .panel-body {
  height: 500px;
}

.panel-600 > .panel-body {
  height: 600px;
}

.panel-100.panel-max-height > .panel-body {
  height: auto;
  max-height: 100px;
}

.panel-200.panel-max-height > .panel-body {
  height: auto;
  max-height: 200px;
}

.panel-300.panel-max-height > .panel-body {
  height: auto;
  max-height: 300px;
}

.panel-400.panel-max-height > .panel-body {
  height: auto;
  max-height: 400px;
}

.panel-500.panel-max-height > .panel-body {
  height: auto;
  max-height: 500px;
}

.panel-600.panel-max-height > .panel-body {
  height: auto;
  max-height: 600px;
}

.panel-body > :last-child {
  margin-bottom: 0;
}

.panel-heading > .panel-title > a {
  text-decoration: none;
  display: block;
}

.panel-heading > .panel-title > a:link, .panel-heading > .panel-title > a:visited, .panel-heading > .panel-title > a:focus, .panel-heading > .panel-title > a:hover, .panel-heading > .panel-title > a:active {
  color: #333;
}

.panel-heading > .panel-title > a > span > i.fa, .panel-heading > .panel-title > span > i.fa {
  -o-transition: all .4s ease;
  transition: all .4s;
  display: none;
}

.panel-heading > .panel-title > a > span > i.fa.fa-minus, .panel-heading > .panel-title > span > i.fa.fa-minus, .panel-heading > .panel-title > a.collapsed > span > i.fa.fa-plus, .panel-heading > .panel-title.collapsed > span > i.fa.fa-plus, .panel-heading.collapsed > .panel-title > span > i.fa.fa-plus {
  display: block;
}

.panel-heading > .panel-title > a.collapsed > span > i.fa.fa-minus, .panel-heading > .panel-title.collapsed > span > i.fa.fa-minus, .panel-heading.collapsed > .panel-title > span > i.fa.fa-minus {
  display: none;
}

.panel-heading.collapsed {
  border-bottom: 0;
}

.panel-group > .panel > .panel-collapse > .panel-footer {
  border-top: 1px solid #ddd;
}

@media (width <= 991px) {
  .panel-100 .panel-body, .panel-200 .panel-body, .panel-300 .panel-body, .panel-400 .panel-body, .panel-500 .panel-body, .panel-600 .panel-body {
    height: auto;
    overflow: visible;
  }
}

.panel.panel-plain {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  background-color: #0000;
  border: 0;
  border-radius: 0;
  margin: 0 0 10px;
}

.panel.panel-plain > .panel-heading {
  background-color: #0000;
  margin: 0 0 5px;
  padding: 0;
}

.panel.panel-plain > .panel-heading > .panel-title {
  font-size: 18px;
}

.panel.panel-plain > .panel-heading > .panel-title > a {
  display: block;
}

.panel.panel-plain > .panel-heading > .panel-title > a > i.fa {
  margin-right: 5px;
}

.panel.panel-plain > .panel-heading > .panel-title > a > i.fa, .panel > .panel-heading > .panel-title > span.pull-right > a > i.fa {
  -o-transition: all .4s ease;
  transition: all .4s;
}

.panel.panel-plain.active > .panel-heading > .panel-title > a > i.fa.fa-chevron-right {
  transform: rotate(90deg);
}

.panel.active > .panel-heading > .panel-title > span.pull-right > a > i.fa.fa-chevron-down {
  transform: rotate(180deg);
}

.panel.panel-plain > .panel-heading + .panel-collapse > .panel-body {
  border-top: 0;
  margin: 0;
  padding: 0;
}

.panel.panel-plain > .panel-heading + .panel-collapse > .panel-body > p:last-child {
  margin-bottom: 15px;
}

table > thead > tr > th.narrow, table > thead > tr > td.narrow, table > tbody > tr > th.narrow, table > tbody > tr > td.narrow, table > tfoot > tr > th.narrow, table > tfoot > tr > td.narrow {
  white-space: nowrap;
  width: 1%;
}

table > thead > tr > th.narrow > span.collapse.in, table > thead > tr > td.narrow > span.collapse.in, table > tbody > tr > th.narrow > span.collapse.in, table > tbody > tr > td.narrow > span.collapse.in, table > tfoot > tr > th.narrow > span.collapse.in, table > tfoot > tr > td.narrow > span.collapse.in {
  display: inline-block;
}

table.table > caption {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
  padding: 15px 0;
}

table.table > caption > h1, table.table > caption > h2, table.table > caption > h3, table.table > caption > h4, table.table > caption > h5, table.table > caption > h6 {
  margin: 0;
}

table.table > caption.legend {
  width: 100%;
  font-size: 21px;
  line-height: inherit;
  color: #333;
  text-align: left;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
  margin-bottom: 20px;
  padding: 0;
}

.table.table-striped tbody tr.odd > th, .table.table-striped tbody tr.odd > td {
  background-color: #f9f9f9;
}

.table.table-striped tbody tr.even > th, .table.table-striped tbody tr.even > td {
  background-color: #0000;
}

.table.table-amplified thead > tr > th, .table.table-amplified thead > tr > td, .table.table-amplified tbody > tr > th, .table.table-amplified tbody > tr > td, .table.table-amplified tfoot > tr > td {
  padding: 16px;
}

.table.table-condensed > caption, .table.table-condensed thead > tr > th, .table.table-condensed thead > tr > td, .table.table-condensed tbody > tr > th, .table.table-condensed tbody > tr > td, .table.table-condensed tfoot > tr > td {
  padding: 4px;
}

.table > tbody > tr.clickable {
  cursor: pointer;
}

.table > tbody > tr.collapse.in {
  display: table-row;
}

.table > tbody > tr.collapse.in > td {
  background-color: #f9f9f9;
}

.table > thead.text-top > tr > th, .table > tbody.text-top > tr > th, .table > tbody.text-top > tr > td, .table > thead > tr.text-top > th, .table > tbody > tr.text-top > th, .table > tbody > tr.text-top > td, tr.text-top th, tr.text-top td, th.text-top, td.text-top, .table > thead > tr > th.text-top, .table > tbody > tr > th.text-top, .table > tbody > tr > td.text-top {
  vertical-align: top;
}

.table > thead.text-middle > tr > th, .table > tbody.text-middle > tr > th, .table > tbody.text-middle > tr > td, .table > tfoot.text-middle > tr > th, .table > tfoot.text-middle > tr > td, .table > thead > tr.text-middle > th, .table > tbody > tr.text-middle > th, .table > tbody > tr.text-middle > td, .table > tfoot > tr.text-middle > th, .table > tfoot > tr.text-middle > td, tr.text-middle th, tr.text-middle td, th.text-middle, td.text-middle, .table > thead > tr > th.text-middle, .table > tbody > tr > th.text-middle, .table > tbody > tr > td.text-middle {
  vertical-align: middle;
}

.table > thead.text-bottom > tr > th, .table > tbody.text-bottom > tr > th, .table > tbody.text-bottom > tr > td, .table > thead > tr.text-bottom > th, .table > tbody > tr.text-bottom > th, .table > tbody > tr.text-bottom > td, tr.text-bottom th, tr.text-bottom td, th.text-bottom, td.text-bottom, .table > thead > tr > th.text-bottom, .table > tbody > tr > th.text-bottom, .table > tbody > tr > td.text-bottom {
  vertical-align: bottom;
}

.table > thead > tr.text-bottom th.text-top, .table > thead > tr.text-bottom td.text-top, .table > thead > tr.text-middle th.text-top, .table > thead > tr.text-middle td.text-top, .table > tbody > tr.text-bottom th.text-top, .table > tbody > tr.text-bottom td.text-top, .table > tbody > tr.text-middle th.text-top, .table > tbody > tr.text-middle td.text-top {
  vertical-align: top;
}

.table > thead > tr.text-bottom th.text-middle, .table > thead > tr.text-bottom td.text-middle, .table > thead > tr.text-top th.text-middle, .table > thead > tr.text-top td.text-middle, .table > tbody > tr.text-bottom th.text-middle, .table > tbody > tr.text-bottom td.text-middle, .table > tbody > tr.text-top th.text-middle, .table > tbody > tr.text-top td.text-middle {
  vertical-align: middle;
}

.table > thead > tr.text-middle th.text-bottom, .table > thead > tr.text-middle td.text-bottom, .table > thead > tr.text-top th.text-bottom, .table > thead > tr.text-top td.text-bottom, .table > tbody > tr.text-middle th.text-bottom, .table > tbody > tr.text-middle td.text-bottom, .table > tbody > tr.text-top th.text-bottom, .table > tbody > tr.text-top td.text-bottom {
  vertical-align: bottom;
}

.text-left, .table > thead > tr.text-left > th, .table > tbody > tr.text-left > th, .table > tbody > tr.text-left > td, tr.text-left th, tr.text-left td, .form-horizontal .control-label.text-left {
  text-align: left;
}

.text-center, .table > thead > tr.text-center > th, .table > tbody > tr.text-center > th, .table > tbody > tr.text-center > td, tr.text-center th, tr.text-center td, .form-horizontal .control-label.text-center {
  text-align: center;
}

.text-right, .table > thead > tr.text-right > th, .table > tbody > tr.text-right > th, .table > tbody > tr.text-right > td, tr.text-right th, tr.text-right td, .form-horizontal .control-label.text-right {
  text-align: right;
}

.table tr > th.no-wrap, .table tr > td.no-wrap {
  white-space: nowrap;
}

.table.rowlink td:not(.rowlink-skip), .table .rowlink td:not(.rowlink-skip) {
  cursor: pointer;
}

.form-control-table {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px #00000013;
}

.panel > table.table:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.panel > table.table:first-child thead tr th:first-child, .panel > table.table:first-child thead tr td:first-child, .panel > table.table:first-child tbody:first-child tr th:first-child, .panel > table.table:first-child tbody:first-child tr td:first-child {
  border-top-left-radius: 4px;
}

.panel > table.table:first-child thead tr th:last-child, .panel > table.table:first-child thead tr td:last-child, .panel > table.table:first-child tbody:first-child tr th:last-child, .panel > table.table:first-child tbody:first-child tr td:last-child {
  border-top-right-radius: 4px;
}

.panel > table.table:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.panel > table.table:last-child thead:last-child tr:last-child th:first-child, .panel > table.table:last-child thead:last-child tr td:first-child, .panel > table.table:last-child tbody:last-child tr:last-child th:first-child, .panel > table.table:last-child tbody:last-child tr:last-child td:first-child {
  border-bottom-left-radius: 4px;
}

.panel > table.table:last-child thead:last-child tr th:last-child, .panel > table.table:last-child thead:last-child tr td:last-child, .panel > table.table:last-child tbody:last-child tr th:last-child, .panel > table.table:last-child tbody:last-child tr td:last-child {
  border-bottom-right-radius: 4px;
}

.panel.panel-default > table.table:first-child thead tr th {
  background-color: #f5f5f5;
}

.panel.panel-primary > table.table:first-child thead tr th {
  background-color: #428bca;
}

.panel.panel-success > table.table:first-child thead tr th {
  background-color: #dff0d8;
}

.panel.panel-warning > table.table:first-child thead tr th {
  background-color: #fcf8e3;
}

.panel.panel-danger > table.table:first-child thead tr th {
  background-color: #f2dede;
}

.panel.panel-info > table.table:first-child thead tr th {
  background-color: #d9edf7;
}

table.table > tbody > tr.danger td {
  border-top-width: 2px;
  border-top-color: red;
}

table.table > tbody > tr.danger.top > td {
  border-top-color: red;
}

table.table > tbody > tr.danger.bottom > td {
  border-top-width: 0;
  border-bottom-width: 2px;
  border-bottom-color: red;
}

table.table > tbody > tr.danger.top > td[rowspan] {
  border-bottom-width: 2px;
  border-bottom-color: red;
}

table.table > tbody > tr.warning > td > p.first, table.table > tbody > tr.danger > td > p.first {
  margin: 0;
}

.alert:empty {
  display: none;
}

.alert {
  -o-transition: all .4s ease;
  transition: all .4s;
}

.alert-default {
  background-color: #f5f5f5;
}

.alert :first-child {
  margin-top: 0;
}

.alert :last-child {
  margin-bottom: 0;
}

.alert .btn {
  margin-top: -7px;
}

p.pagination {
  margin: 0;
  padding: 7px 0;
}

.thumbnail .layered {
  display: block;
  position: relative;
}

.thumbnail .layered .description {
  color: #fff;
  opacity: 0;
  background-color: #000000bf;
  padding: 10px;
  transition: opacity .375s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.thumbnail:hover .layered .description {
  opacity: 1;
}

.thumbnail .layered .description h3 {
  color: #fff;
  margin: 0;
  font-size: 12px;
}

.thumbnail .layered .fa-youtube-play {
  color: red;
  text-align: center;
  width: 64px;
  height: 64px;
  margin: -32px 0 0 -32px;
  font-size: 64px;
  position: absolute;
  top: 50%;
  left: 50%;
  text-decoration: none !important;
}

.thumbnail .layered .fa-youtube-play.small {
  width: 32px;
  height: 32px;
  margin: -16px 0 0 -16px;
  font-size: 32px;
}

a.img-thumbnail {
  display: block;
  position: relative;
}

a.img-thumbnail > div.img-wrapper {
  background-color: #ddd;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
}

a.img-thumbnail.img-square > div.img-wrapper {
  position: absolute;
  inset: 2px;
}

a.img-thumbnail > div.img-wrapper > img.img-responsive.img-adjusted {
  display: none;
}

a.img-icon > span[class*="fa-"] {
  color: #fff;
  background-color: #222;
  padding: 2px;
  position: absolute;
  top: 6px;
  right: 6px;
}

hr.white {
  border-top-color: #fff;
}

hr.black {
  border-top-color: #000;
}

hr.red {
  border-top-color: red;
}

hr.no-margin {
  margin: 0;
}

.form-tight hr {
  margin-top: 10px;
  margin-bottom: 10px;
}

form fieldset hr {
  border-color: #e5e5e5;
}

.paypal {
  background-position: 50%;
  background-repeat: no-repeat;
  width: 59px;
  height: 16px;
  margin: 0 10px 0 0;
  display: inline-block;
}

.text-center img.img-responsive, .text-center-lg img.img-responsive, .text-center-md img.img-responsive, .text-center-sm img.img-responsive, .text-center-xs img.img-responsive {
  margin: 0 auto;
}

.text-right img.img-responsive, .text-right-lg img.img-responsive, .text-right-md img.img-responsive, .text-right-sm img.img-responsive, .text-right-xs img.img-responsive {
  float: right;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-sm {
  font-size: 80%;
}

.text-md {
  font-size: 100%;
}

.text-lg {
  font-size: 120%;
}

.text-xl {
  font-size: 150%;
}

.text-2x {
  font-size: 200%;
}

.text-3x {
  font-size: 300%;
}

.text-4x {
  font-size: 400%;
}

.text-shadow {
  text-shadow: 0 2px 2px #00000080;
}

.box-shadow {
  box-shadow: 0 1px 4px #000000bf;
}

small, .small, .glyphicon > .glyphicon.small:before {
  font-size: 85%;
}

.small > blockquote {
  font-size: 14px;
}

.text-italic {
  font-style: italic;
}

.text-bold {
  font-weight: bold;
}

.text-weight-normal {
  font-weight: normal;
}

i.fa.success {
  color: #3c763d;
}

i.fa.warning {
  color: #8a6d3b;
}

i.fa.danger {
  color: #a94442;
}

i.fa.info {
  color: #31708f;
}

.btn > .fa.pull-right {
  line-height: 1.5em;
}

pre {
  word-wrap: normal;
  overflow: auto;
}

div.pre {
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin: 0 0 10px;
  padding: 6px;
  position: relative;
}

div.pre.line-numbers, div.form-control.line-numbers {
  padding-left: 48px;
}

div.pre pre {
  border: 0;
  margin: 0;
  padding: 6px;
}

div.pre.line-numbers > span.line-numbers, div.form-control.line-numbers > span.line-numbers {
  background-color: #ccc;
  border-right: 1px solid #ccc;
  width: 42px;
  padding: 12px 6px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

div.pre.line-numbers > span.line-numbers > span.line-number, div.form-control.line-numbers > span.line-numbers > span.line-number {
  text-align: right;
  color: #f5f5f5;
  font-family: Menlo, Monaco, Consolas, Courier New, monospace;
  font-size: 13px;
  line-height: 1.42857;
  display: block;
}

div.pre.line-numbers > span.error-overlay, div.form-control.line-numbers > span.error-overlay {
  color: red;
  background-color: #ff000040;
  border-radius: 4px 0 0 4px;
  padding: 0 6px;
  font-size: 13px;
  line-height: 1.42857;
  display: none;
  position: absolute;
  top: 0;
  left: -25px;
  right: 0;
}

div.form-control.line-numbers > span.line-numbers > span.line-number, div.form-control.line-numbers > span.error-overlay {
  font-size: 14px;
}

.display-inline {
  display: inline;
}

.table-responsive {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  width: 100%;
  max-height: 500px;
  padding: 0 14px 14px 0;
  overflow: auto;
}

.table-responsive.table-fixed {
  height: 500px;
}

.table-responsive > table.table {
  margin-bottom: 0;
}

.table-responsive::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

pre::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.dropup .dropdown-menu::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.panel-100 .panel-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.panel-200 .panel-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.panel-300 .panel-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.panel-400 .panel-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.panel-500 .panel-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.panel-600 .panel-body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

textarea.form-control::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

select.form-control::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.table-responsive::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #0000004d;
}

pre::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #0000004d;
}

.dropup .dropdown-menu::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #0000004d;
}

.panel-100 .panel-body::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #0000004d;
}

.panel-200 .panel-body::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #0000004d;
}

.panel-300 .panel-body::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #0000004d;
}

.panel-400 .panel-body::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #0000004d;
}

.panel-500 .panel-body::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #0000004d;
}

.panel-600 .panel-body::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #0000004d;
}

textarea.form-control::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #0000004d;
}

select.form-control::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #0000004d;
}

.table-responsive::-webkit-scrollbar-thumb {
  -o-transition: all .3s ease;
  background: #33333340;
  border-radius: 10px;
  transition: all .3s;
  -webkit-box-shadow: inset 0 0 6px #00000080;
}

pre::-webkit-scrollbar-thumb {
  -o-transition: all .3s ease;
  background: #33333340;
  border-radius: 10px;
  transition: all .3s;
  -webkit-box-shadow: inset 0 0 6px #00000080;
}

.dropup .dropdown-menu::-webkit-scrollbar-thumb {
  -o-transition: all .3s ease;
  background: #33333340;
  border-radius: 10px;
  transition: all .3s;
  -webkit-box-shadow: inset 0 0 6px #00000080;
}

.panel-100 .panel-body::-webkit-scrollbar-thumb {
  -o-transition: all .3s ease;
  background: #33333340;
  border-radius: 10px;
  transition: all .3s;
  -webkit-box-shadow: inset 0 0 6px #00000080;
}

.panel-200 .panel-body::-webkit-scrollbar-thumb {
  -o-transition: all .3s ease;
  background: #33333340;
  border-radius: 10px;
  transition: all .3s;
  -webkit-box-shadow: inset 0 0 6px #00000080;
}

.panel-300 .panel-body::-webkit-scrollbar-thumb {
  -o-transition: all .3s ease;
  background: #33333340;
  border-radius: 10px;
  transition: all .3s;
  -webkit-box-shadow: inset 0 0 6px #00000080;
}

.panel-400 .panel-body::-webkit-scrollbar-thumb {
  -o-transition: all .3s ease;
  background: #33333340;
  border-radius: 10px;
  transition: all .3s;
  -webkit-box-shadow: inset 0 0 6px #00000080;
}

.panel-500 .panel-body::-webkit-scrollbar-thumb {
  -o-transition: all .3s ease;
  background: #33333340;
  border-radius: 10px;
  transition: all .3s;
  -webkit-box-shadow: inset 0 0 6px #00000080;
}

.panel-600 .panel-body::-webkit-scrollbar-thumb {
  -o-transition: all .3s ease;
  background: #33333340;
  border-radius: 10px;
  transition: all .3s;
  -webkit-box-shadow: inset 0 0 6px #00000080;
}

textarea.form-control::-webkit-scrollbar-thumb {
  -o-transition: all .3s ease;
  background: #33333340;
  border-radius: 10px;
  transition: all .3s;
  -webkit-box-shadow: inset 0 0 6px #00000080;
}

select.form-control::-webkit-scrollbar-thumb {
  -o-transition: all .3s ease;
  background: #33333340;
  border-radius: 10px;
  transition: all .3s;
  -webkit-box-shadow: inset 0 0 6px #00000080;
}

.table-responsive::-webkit-scrollbar-thumb:hover {
  background: #33333380;
}

.table-responsive::-webkit-scrollbar-thumb:active {
  background: #33333380;
}

pre::-webkit-scrollbar-thumb:hover {
  background: #33333380;
}

pre::-webkit-scrollbar-thumb:active {
  background: #33333380;
}

.dropup .dropdown-menu::-webkit-scrollbar-thumb:hover {
  background: #33333380;
}

.dropup .dropdown-menu::-webkit-scrollbar-thumb:active {
  background: #33333380;
}

.panel-100 .panel-body::-webkit-scrollbar-thumb:hover {
  background: #33333380;
}

.panel-100 .panel-body::-webkit-scrollbar-thumb:active {
  background: #33333380;
}

.panel-200 .panel-body::-webkit-scrollbar-thumb:hover {
  background: #33333380;
}

.panel-200 .panel-body::-webkit-scrollbar-thumb:active {
  background: #33333380;
}

.panel-300 .panel-body::-webkit-scrollbar-thumb:hover {
  background: #33333380;
}

.panel-300 .panel-body::-webkit-scrollbar-thumb:active {
  background: #33333380;
}

.panel-400 .panel-body::-webkit-scrollbar-thumb:hover {
  background: #33333380;
}

.panel-400 .panel-body::-webkit-scrollbar-thumb:active {
  background: #33333380;
}

.panel-500 .panel-body::-webkit-scrollbar-thumb:hover {
  background: #33333380;
}

.panel-500 .panel-body::-webkit-scrollbar-thumb:active {
  background: #33333380;
}

.panel-600 .panel-body::-webkit-scrollbar-thumb:hover {
  background: #33333380;
}

.panel-600 .panel-body::-webkit-scrollbar-thumb:active {
  background: #33333380;
}

textarea.form-control::-webkit-scrollbar-thumb:hover {
  background: #33333380;
}

textarea.form-control::-webkit-scrollbar-thumb:active {
  background: #33333380;
}

select.form-control::-webkit-scrollbar-thumb:hover {
  background: #33333380;
}

select.form-control::-webkit-scrollbar-thumb:active {
  background: #33333380;
}

.table-responsive::-webkit-scrollbar-thumb:window-inactive {
  background: #33333320;
}

pre::-webkit-scrollbar-thumb:window-inactive {
  background: #33333320;
}

.dropup .dropdown-menu::-webkit-scrollbar-thumb:window-inactive {
  background: #33333320;
}

.panel-100 .panel-body::-webkit-scrollbar-thumb:window-inactive {
  background: #33333320;
}

.panel-200 .panel-body::-webkit-scrollbar-thumb:window-inactive {
  background: #33333320;
}

.panel-300 .panel-body::-webkit-scrollbar-thumb:window-inactive {
  background: #33333320;
}

.panel-400 .panel-body::-webkit-scrollbar-thumb:window-inactive {
  background: #33333320;
}

.panel-500 .panel-body::-webkit-scrollbar-thumb:window-inactive {
  background: #33333320;
}

.panel-600 .panel-body::-webkit-scrollbar-thumb:window-inactive {
  background: #33333320;
}

textarea.form-control::-webkit-scrollbar-thumb:window-inactive {
  background: #33333320;
}

select.form-control::-webkit-scrollbar-thumb:window-inactive {
  background: #33333320;
}

div[class*="col-"].padded > :last-child {
  margin-bottom: 0;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-margin-right {
  margin-right: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-margin-left {
  margin-left: 0 !important;
}

.margin-all {
  margin: 15px;
}

.margin-all-md {
  margin: 20px;
}

.margin-all-lg {
  margin: 30px;
}

.margin-top {
  margin-top: 15px;
}

.margin-top-md {
  margin-top: 20px;
}

.margin-top-lg {
  margin-top: 30px;
}

.margin-top-xl {
  margin-top: 45px;
}

.margin-top-2x {
  margin-top: 60px;
}

.margin-top-3x {
  margin-top: 120px;
}

.margin-bottom {
  margin-bottom: 15px;
}

.margin-bottom-md {
  margin-bottom: 20px;
}

.margin-bottom-lg {
  margin-bottom: 30px;
}

.margin-bottom-xl {
  margin-bottom: 60px;
}

.margin-bottom-2x {
  margin-bottom: 90px;
}

.margin-right-xs {
  margin-right: 5px;
}

.margin-right {
  margin-right: 15px;
}

.margin-right-md {
  margin-right: 20px;
}

.margin-right-lg {
  margin-right: 30px;
}

.margin-left {
  margin-left: 15px;
}

.margin-left-md {
  margin-left: 20px;
}

.margin-left-lg {
  margin-left: 30px;
}

.no-padding, table > thead > tr > th.no-padding, table > tbody > tr > td.no-padding {
  padding: 0;
}

.no-padding-top, table > thead > tr > th.no-padding-top, table > tbody > tr > td.no-padding-top {
  padding-top: 0;
}

.no-padding-right, table > thead > tr > th.no-padding-right, table > tbody > tr > td.no-padding-right {
  padding-right: 0;
}

.no-padding-bottom, table > thead > tr > th.no-padding-bottom, table > tbody > tr > td.no-padding-bottom {
  padding-bottom: 0;
}

.no-padding-left, table > thead > tr > th.no-padding-left, table > tbody > tr > td.no-padding-left {
  padding-left: 0;
}

.padding-all {
  padding: 15px;
}

.padding-all-md {
  padding: 20px;
}

.padding-all-lg {
  padding: 30px;
}

.padding-all-xl {
  padding: 45px;
}

.padding-top {
  padding-top: 15px;
}

.padding-top-md {
  padding-top: 20px;
}

.padding-top-lg {
  padding-top: 30px;
}

.padding-top-xl {
  padding-top: 45px;
}

.padding-bottom {
  padding-bottom: 15px;
}

.padding-bottom-md {
  padding-bottom: 20px;
}

.padding-bottom-lg {
  padding-bottom: 30px;
}

.padding-bottom-xl {
  padding-bottom: 45px;
}

.padding-right {
  padding-right: 10px;
}

.padding-right-md {
  padding-right: 20px;
}

.padding-right-lg {
  padding-right: 30px;
}

.padding-right-xl {
  padding-right: 45px;
}

.padding-left {
  padding-left: 10px;
}

.padding-left-md {
  padding-left: 20px;
}

.padding-left-lg {
  padding-left: 30px;
}

.padding-left-xl {
  padding-left: 45px;
}

.max-width-500 {
  max-width: 500px;
}

.max-width-1000 {
  max-width: 1000px;
}

.line-height-1 {
  line-height: 1;
}

.line-height-2 {
  line-height: 2;
}

.line-height-3 {
  line-height: 3;
}

.line-height-4 {
  line-height: 4;
}

@media (width >= 1200px) {
  .container .container {
    width: 100%;
  }

  .col-lg-offset-0 {
    margin-left: 0;
  }

  .no-pull-lg {
    float: none !important;
  }

  .pull-left-lg {
    float: left !important;
  }

  .pull-right-lg {
    float: right !important;
  }

  .text-left-lg {
    text-align: left;
  }

  .text-center-lg {
    text-align: center;
  }

  .text-right-lg {
    text-align: right;
  }

  .no-padding-lg {
    padding: 0;
  }

  .no-padding-top-lg {
    padding-top: 0;
  }

  .no-padding-right-lg {
    padding-right: 0;
  }

  .no-padding-bottom-lg {
    padding-bottom: 0;
  }

  .no-padding-left-lg {
    padding-left: 0;
  }

  .no-margin-lg {
    margin: 0;
  }

  .no-margin-top-lg {
    margin-top: 0;
  }

  .no-margin-right-lg {
    margin-right: 0;
  }

  .no-margin-bottom-lg {
    margin-bottom: 0;
  }

  .no-margin-left-lg {
    margin-left: 0;
  }
}

@media (width <= 1199px) and (width >= 992px) {
  .container .container {
    width: 100%;
  }

  .col-md-offset-0 {
    margin-left: 0;
  }

  .no-pull-md {
    float: none !important;
  }

  .pull-left-md {
    float: left !important;
  }

  .pull-right-md {
    float: right !important;
  }

  .text-left-md {
    text-align: left;
  }

  .text-center-md {
    text-align: center;
  }

  .text-right-md {
    text-align: right;
  }
}

@media (width <= 991px) and (width >= 768px) {
  .container .container {
    width: 100%;
  }

  .col-sm-offset-0 {
    margin-left: 0;
  }

  .no-pull-sm {
    float: none !important;
  }

  .pull-left-sm {
    float: left !important;
  }

  .pull-right-sm {
    float: right !important;
  }

  .text-left-sm {
    text-align: left;
  }

  .text-center-sm {
    text-align: center;
  }

  .text-right-sm {
    text-align: right;
  }
}

@media (width <= 767px) {
  .container .container {
    width: 100%;
  }

  .col-xs-offset-0 {
    margin-left: 0;
  }

  .col-xs-offset-1 {
    margin-left: 8.33333%;
  }

  .col-xs-offset-2 {
    margin-left: 16.6667%;
  }

  .col-xs-offset-3 {
    margin-left: 25%;
  }

  .col-xs-offset-4 {
    margin-left: 33.3333%;
  }

  .col-xs-offset-5 {
    margin-left: 41.6667%;
  }

  .col-xs-offset-6 {
    margin-left: 50%;
  }

  .col-xs-offset-7 {
    margin-left: 58.3333%;
  }

  .col-xs-offset-8 {
    margin-left: 66.6667%;
  }

  .col-xs-offset-9 {
    margin-left: 75%;
  }

  .col-xs-offset-10 {
    margin-left: 83.3333%;
  }

  .col-xs-offset-11 {
    margin-left: 91.6667%;
  }

  .no-pull-xs {
    float: none !important;
  }

  .pull-left-xs {
    float: left;
  }

  .pull-right-xs {
    float: right;
  }

  .text-left-xs {
    text-align: left;
  }

  .text-center-xs {
    text-align: center;
  }

  .text-right-xs {
    text-align: right;
  }
}

.fileinput {
  display: block;
}

.fileinput .uneditable-input {
  overflow: hidden;
}

.fileinput .uneditable-input .fileinput-value, .uneditable-input .fileinput-value {
  height: 1.42857em;
  display: block;
  overflow: hidden;
}

.fileinput .thumbnail {
  margin-bottom: 20px;
  display: block;
}

.input-group .file-control {
  height: 35px;
}

.required .input-group > .input-group-addon.required {
  cursor: pointer;
}

.input-group .input-group-addon.btn {
  display: table-cell;
}

.fileinput-exists .fileinput-new, .fileinput-new .fileinput-exists {
  display: none !important;
}

.position-relative {
  position: relative;
}
/*# sourceMappingURL=index.37ed0826.css.map */
